import { Howl, Howler } from 'howler';
import PhonemeSounds from 'assets/PhonemeAudio';

let soundPlayer: Howl | null = null;

function findSoundIndex(soundList: NodeListOf<ChildNode>, soundNode: HTMLElement): number | null {
  for (let index = 0; index < soundList.length; index += 1) {
    if (soundList[index] === soundNode) {
      return index;
    }
  }
  return null;
}

let currentWord: Node | null = null;
let currentWordCopy: Node | null = null;
let currentSoundIndex = -1;

function clearFocusWord(): void {
  currentWord = null;
  currentSoundIndex = -1;
  const popupElement = document.getElementById('epc-popup');
  if (popupElement) {
    if (currentWordCopy !== null) {
      popupElement.removeChild(currentWordCopy);
      currentWordCopy = null;
    }
    $(popupElement).hide();
  }
}

function setFocusWord(wordNode: Node, soundIndex: number): void {
  // Clear the current word, if it's changed
  if (currentWord !== null && currentWord !== wordNode) {
    clearFocusWord();
  }

  // If we don't have an active pop-up, create one
  if (currentWord === null) {
    // New word - we need to set up our magnified clone from scratch
    currentWord = wordNode;
    currentWordCopy = wordNode.cloneNode(true);
    const popupElement = document.getElementById('epc-popup');
    if (popupElement) {
      popupElement.appendChild(currentWordCopy);

      // Re-position the popup
      const wordPosition = $(currentWord).offset();
      if (!wordPosition) {
        return;
      }
      const wordHeight = $(currentWord).height();
      if (!wordHeight) {
        return;
      }
      $(popupElement).css({
        left: `${wordPosition.left - 16}px`,
        top: `${wordPosition.top + wordHeight + 8}px`,
      });
      $(popupElement).show();
    }
  }

  // If we already have an active element, deactivate it
  if (currentSoundIndex !== -1 && currentSoundIndex !== soundIndex) {
    if (currentWordCopy) {
      const childNode = currentWordCopy.childNodes[currentSoundIndex];
      (childNode as Element).classList.remove('epc-active');
    }
    currentSoundIndex = -1;
  }

  // Activate the correct element
  if (currentSoundIndex !== soundIndex) {
    currentSoundIndex = soundIndex;
    if (currentWordCopy) {
      const childNode = currentWordCopy.childNodes[currentSoundIndex];
      (childNode as Element).classList.add('epc-active');
    }
  }
}

let hideTimer: number | undefined;

let soundList: string[] = [];
function nextSound(): void {
  if (soundList.length > 0) {
    if (soundPlayer === null) {
      soundPlayer = new Howl({
        src: PhonemeSounds.src,
        sprite: (PhonemeSounds.sprite as unknown) as IHowlSoundSpriteDefinition,
        onload: (): void => {
          nextSound();
        },
      });
    } else {
      soundPlayer.once('end', nextSound);
      soundPlayer.play(soundList.shift());
    }
  }
}

const sndMap: { [index: string]: string } = {
  thminus: 'th',
  thplus: 'tth',
  x: 'ks',
  qu: 'kw',
  a: 'v01',
  e: 'v02',
  i: 'v03',
  o: 'v04',
  u: 'v05',
};

export default {
  PlaySound(soundName: string): void {
    let sSoundName = soundName;
    if (sndMap[sSoundName]) {
      sSoundName = sndMap[sSoundName];
    }
    if (sSoundName.match(/^[0-9]+$/)) {
      sSoundName = `v${sSoundName.padStart(2, '0')}`;
    }

    soundList = [sSoundName];
    nextSound();
  },
};

$(
  (): void => {
    const popupElement = document.createElement('div');
    popupElement.id = 'epc-popup';
    popupElement.innerHTML =
      '<ruby class="epc-active"><rb>&#8203;</rb><rt>&#8203;&#8203;</rt></ruby>';
    document.body.appendChild(popupElement);
    $(popupElement).hide();

    $('body').on(
      'mouseenter',
      '[data-sounds]',
      (event): void => {
        // Locate the word this sound is a part of
        const partNode = event.currentTarget as HTMLElement;
        const wordNode = partNode.parentNode;

        // Find out which sound we've hovered over
        if (wordNode) {
          const soundIndex = findSoundIndex(wordNode.childNodes, partNode);
          if (soundIndex === null) {
            return;
          }

          // Set the focused word
          setFocusWord(wordNode, soundIndex);
        }

        // Disable the hide timer
        window.clearTimeout(hideTimer);
        hideTimer = undefined;
      },
    );

    $('body').on(
      'click',
      '[data-sounds]',
      (event): void => {
        const partNode = event.currentTarget as HTMLElement;
        const soundString = partNode.getAttribute('data-sounds');
        if (soundString !== null && soundString.trim() !== '') {
          soundList = soundString.split(' ');
          nextSound();
        }
      },
    );

    function hideBox(): void {
      window.clearTimeout(hideTimer);
      hideTimer = undefined;
      clearFocusWord();
    }

    $('body').on(
      'mouseleave',
      '[data-sounds]',
      (): void => {
        if (hideTimer !== null) {
          window.clearTimeout(hideTimer);
        }
        hideTimer = window.setTimeout(hideBox, 400);
      },
    );

    $('#epc-popup').on('mouseenter', hideBox);
  },
);
