import { library, dom } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronLeft,
  faChevronRight,
  faCircleNotch,
  faCompress,
  faCompressArrowsAlt,
  faEdit,
  faEnvelope,
  faEnvelopeOpenText,
  faExpand,
  faExpandArrowsAlt,
  faPencilAlt,
  faSearch,
  faShoppingBasket,
  faSignInAlt,
  faSignOutAlt,
  faStoreAlt,
  faToggleOff,
  faToggleOn,
  faUser,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons';

import { faFacebookF, faPinterest, faInstagram } from '@fortawesome/free-brands-svg-icons';

library.add([
  faChevronLeft,
  faChevronRight,
  faCircleNotch,
  faCompress,
  faCompressArrowsAlt,
  faEdit,
  faEnvelope,
  faEnvelopeOpenText,
  faExpand,
  faExpandArrowsAlt,
  faPencilAlt,
  faSearch,
  faShoppingBasket,
  faSignInAlt,
  faSignOutAlt,
  faStoreAlt,
  faToggleOff,
  faToggleOn,
  faUser,
  faUserCircle,

  faFacebookF,
  faPinterest,
  faInstagram,
]);

// This will kick of the initial replacement of i to svg tags and configure a MutationObserver
dom.watch();
