import 'scss/main.scss';

import 'lazysizes';

import './fontawesome';

import 'lib/popup';
import objectFitImages from 'object-fit-images';

// Shrink the main nav bar
$(window).scroll(
  (): void => {
    if (($(document).scrollTop() || 0) > 30) {
      $('nav').addClass('shrink');
    } else {
      $('nav').removeClass('shrink');
    }
  },
);

function toggleDropdown(e: JQueryEventObject): void {
  const dropdownElement = $(e.target).closest('.dropdown');
  const menuElements = $('.dropdown-menu', dropdownElement);
  setTimeout(
    (): void => {
      const shouldOpen = e.type !== 'click' && dropdownElement.is(':hover');
      menuElements.toggleClass('show', shouldOpen);
      dropdownElement.toggleClass('show', shouldOpen);
      $('[data-toggle="dropdown"]', dropdownElement).attr(
        'aria-expanded',
        shouldOpen ? 'true' : 'false',
      );
    },
    e.type === 'mouseleave' ? 300 : 0,
  );
}

$('body')
  .on('mouseenter mouseleave', '.dropdown-hover.dropdown', toggleDropdown)
  .on('click', '.dropdown-hover .dropdown-menu a', toggleDropdown);

// Fit images for IE
$(
  (): void => {
    objectFitImages();
  },
);
