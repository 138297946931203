export default {
  src: [require('./phonemes.webm'), require('./phonemes.mp3')],
  sprite: {
    b: [0, 574.3990929705215],
    c: [1500, 349.88662131519277],
    ch: [3000, 679.1383219954649],
    d: [4500, 526.8027210884352],
    f: [6000, 860.7709750566892],
    g: [7500, 528.9115646258508],
    h: [9000, 638.9342403628114],
    j: [10500, 486.5986394557815],
    k: [12000, 297.256235827664],
    l: [13500, 668.6621315192749],
    m: [15000, 843.6281179138323],
    n: [16500, 830.4535147392294],
    ng: [18000, 1125.5328798185928],
    p: [20500, 613.537414965986],
    kw: [22000, 753.1746031746031],
    r: [23500, 848.1632653061233],
    s: [25000, 630.476190476191],
    sh: [26500, 1208.049886621314],
    t: [29000, 168.73015873015973],
    th: [30500, 1844.8752834467114],
    tth: [33000, 1438.6621315192726],
    v: [35500, 995.4421768707462],
    v01: [37000, 936.5079365079367],
    v02: [38500, 705.873015873017],
    v03: [40000, 631.6326530612243],
    v04: [41500, 577.3469387755129],
    v05: [43000, 849.8412698412708],
    v06: [44500, 1064.036281179142],
    v07: [47000, 1191.56462585034],
    v08: [49500, 1012.2448979591852],
    v09: [52000, 1386.8480725623585],
    v10: [54500, 1335.0340136054442],
    v11: [57000, 1024.1950113378664],
    v12: [59500, 996.3038548752863],
    v13: [61000, 1155.7142857142821],
    v14: [63500, 1123.8321995464844],
    v15: [66000, 1354.9659863945608],
    v16: [68500, 785.079365079369],
    v17: [70000, 1167.6643990929706],
    v18: [72500, 880.7029478458048],
    v19: [74000, 1438.6621315192797],
    v20: [76500, 968.3900226757345],
    v21: [78000, 1012.244897959178],
    v22: [80500, 1107.8684807256282],
    w: [83000, 330.0680272108849],
    ks: [84500, 930.9070294784618],
    y: [86000, 609.3197278911617],
    z: [87500, 1305.2607709750532],
    zh: [90000, 964.7392290249428],
  },
};
